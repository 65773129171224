import React from 'react'
import { useMediaQuery } from 'react-responsive';
import { FaLinkedinIn } from 'react-icons/fa';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from 'swiper/modules';

import "./aboutus.css";
import "swiper/css";
import 'swiper/css/pagination';

import ImgHeader from "../../images/aboutus/header.webp";
import ImgUmbra01 from "../../images/aboutus/umbra-01.webp";
import ImgUmbra02 from "../../images/aboutus/umbra-02.webp";
import ImgUmbra03 from "../../images/aboutus/umbra-03.webp";
import ImgUmbra04 from "../../images/aboutus/umbra-04.webp";

// Ajuste de pantalla

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1025 })
  return isDesktop ? children : null
}

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 1024 })
  return isMobile ? children : null
}

export default function OurWork() {

  return (
    <>
      <Desktop>
        <div className='aboutUsContainer'>
          <img className='aboutUsHeader' src={ImgHeader} alt="header" />
          <div className='textContainer'>
            <div className='textAboutUs'>Firelight Animation Studio emerged from the collaboration of its four founding partners: Marc Martín, Yensen Quintero, Berni López, and Gaspar Segura. Their journey began when they worked together at a renowned production company in Barcelona.</div>
            <div className='textAboutUs'>After years of individual experiences around the globe, the group reunited with a unified vision: to establish an animation studio and create captivating content.</div>
            <div className='textAboutUs'>Firelight's founders have extensive backgrounds in the entertainment industry, having worked with prestigious companies such as Netflix, Riot Games, Marvel, Sony Pictures, Red Bull, and Blizzard Entertainment, among others.</div>
            <div className='textAboutUs'>Thanks to all this international experience, the studio can now cover the entire production process for any audiovisual project or related, mainly focusing on the CG and 3D aspects of these: Modeling, Shading, Grooming, Character Design, Concept Art, Rigging, Animation, Lighting & Comp, VFX and Sound Design.</div>
            <div className='textAboutUs'>Take your brand to the next level with Firelight Animation Studio. We specialize in captivating stories and characters that will elevate your company to new heights.</div>
            <div className='textAboutUs'>Ready to bring your ideas to life?&nbsp;
              <a href="/contact-us" style={{textDecoration: 'underline', color: 'inherit'}}>Contact  us now to spark your next project!</a>
            </div>
          </div>
          <div className="umbraContainer">
            <div className="umbraImage">
              <img src={ImgUmbra01} alt="Yensen Quintero" />
              <div id="overlay-01" className="overlay">
                <div className='partner-title'>Yensen Quintero</div>
                <div className='partner-subtitle'>Director & 3D Animator</div>
                <div className='partner-text'>Yensen has worked with various studios worldwide, including those in Spain, Canada, Colombia, and Argentina. Notable collaborations including 'Spiderman: No Way Home' by Marvel, 'KDA' by Riot Games and 'SpongeBob' by Nickelodeon and Netflix. Yensen's passion for high-quality animation led him to become an entrepreneur, where he currently directs and develops innovative projects. His role involves leading and managing the success of the company by exploring new frontiers in animation and audiovisual creation.</div>
                <a href="https://www.linkedin.com/in/yensenquintero/" rel="noopener noreferrer" target='_blank'><FaLinkedinIn className="linkedin-icon"/></a>
              </div>
            </div>
            <div className="umbraImage">
              <img src={ImgUmbra02} alt="Marc Martin" />
              <div id="overlay-02" className="overlay">
                <div className='partner-title'>Marc Martín</div>
                <div className='partner-subtitle'>Art Director & Character Developer Artist</div>
                <div className='partner-text'>Marc is Art Director and Character Development Artist. He embarked on his creative journey in Finland, where he co-founded a video game startup and gained valuable experience. His expertise led him to work on national and international audiovisual projects for renowned studios like Netflix, Animal Logic & Nathan Love. Currently, Marc is focusing on his artistic vision of himself and coordinating various projects, honing his skills in the world of artistic direction.</div>
                <a href="https://www.linkedin.com/in/marc-martin-riera/" rel="noopener noreferrer" target='_blank'><FaLinkedinIn className="linkedin-icon"/></a>
              </div>
            </div>
            <div className="umbraImage">
              <img src={ImgUmbra03} alt="Berni Lopez" />
              <div id="overlay-03" className="overlay">
                <div className='partner-title'>Berni López</div>
                <div className='partner-subtitle'>Executive Producer</div>
                <div className='partner-text'>Berni is a communication enthusiast who loves the media world. I studied my 'Journalism' degree in Barcelona while traveling around 36 different countries. I've been involved in social media environment for so long, creating communities for many international brands and TV shows. Interaction is my natural passion. My philosophy: a great effort springs naturally from a great attitude.</div>
                <a href="https://www.linkedin.com/in/berni-lopez/" rel="noopener noreferrer" target='_blank'><FaLinkedinIn className="linkedin-icon"/></a>
              </div>
            </div>
            <div className="umbraImage">
              <img src={ImgUmbra04} alt="Gaspar Segura" />
              <div id="overlay-04" className="overlay">
                <div className='partner-title'>Gaspar Segura</div>
                <div className='partner-subtitle'>Technical Supervisor</div>
                <div className='partner-text'>Gaspar Segura is a valued partner at our company, bringing a wealth of experience and expertise to the team. Starting out as a CG Generalist, Gaspar has gradually transitioned into a more technical role, demonstrating a keen interest in developing new tools and optimizing processes. He is passionate about problem-solving and is dedicated to ensuring that our workflow runs smoothly. Gaspar's innovative mindset and commitment to excellence make him an invaluable asset to our organization.</div>
                <a href="https://www.linkedin.com/in/gasparsegura/" rel="noopener noreferrer" target='_blank'><FaLinkedinIn className="linkedin-icon"/></a>
              </div>
            </div>
          </div>
        </div>
      </Desktop>
      <Mobile>
        <div className='aboutUsContainer'>
          <img className='aboutUsHeader' src={ImgHeader} alt="header" />
          <div className='textContainer'>
            <div className='textAboutUs'>Firelight Animation Studio emerged from the collaboration of its four founding partners: Marc Martín, Yensen Quintero, Berni López, and Gaspar Segura. Their journey began when they worked together at a renowned production company in Barcelona.</div>
            <div className='textAboutUs'>After years of individual experiences around the globe, the group reunited with a unified vision: to establish an animation studio and create captivating content.</div>
            <div className='textAboutUs'>Firelight's founders have extensive backgrounds in the entertainment industry, having worked with prestigious companies such as Netflix, Riot Games, Marvel, Sony Pictures, Red Bull, and Blizzard Entertainment, among others.</div>
            <div className='textAboutUs'>Thanks to all this international experience, the studio can now cover the entire production process for any audiovisual project or related, mainly focusing on the CG and 3D aspects of these: Modeling, Shading, Grooming, Character Design, Concept Art, Rigging, Animation, Lighting & Comp, VFX and Sound Design.</div>
            <div className='textAboutUs'>Take your brand to the next level with Firelight Animation Studio. We specialize in captivating stories and characters that will elevate your company to new heights.</div>
            <div className='textAboutUs'>Ready to bring your ideas to life?</div>
            <div className='textAboutUs'><a href="/contact-us" style={{textDecoration: 'underline', color: 'inherit'}}>Contact us now to spark your next project!</a></div>
          </div>
          <Swiper pagination={true} modules={[Pagination]} loop={true} className="my-custom-swiper-container">
            <SwiperSlide>
              <div className="umbraImage">
                <img src={ImgUmbra01} alt="Yensen Quintero" />
                <div id="overlay-01" className="overlay">
                  <div className='partner-title'>Yensen Quintero</div>
                  <div className='partner-subtitle'>Director & 3D Animator</div>
                  <div className='partner-text'>Yensen has worked with various studios worldwide, including those in Spain, Canada, Colombia, and Argentina. Notable collaborations including 'Spiderman: No Way Home' by Marvel, 'KDA' by Riot Games and 'SpongeBob' by Nickelodeon and Netflix. Yensen's passion for high-quality animation led him to become an entrepreneur, where he currently directs and develops innovative projects. His role involves leading and managing the success of the company by exploring new frontiers in animation and audiovisual creation.</div>
                  <a href="https://www.linkedin.com/in/yensenquintero/" rel="noopener noreferrer" target='_blank'><FaLinkedinIn className="linkedin-icon"/></a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="umbraImage">
                <img src={ImgUmbra02} alt="Marc Martin" />
                <div id="overlay-02" className="overlay">
                  <div className='partner-title'>Marc Martín</div>
                  <div className='partner-subtitle'>Art Director & Character Developer Artist</div>
                  <div className='partner-text'>Marc is Art Director and Character Development Artist. He embarked on his creative journey in Finland, where he co-founded a video game startup and gained valuable experience. His expertise led him to work on national and international audiovisual projects for renowned studios like Netflix, Animal Logic & Nathan Love. Currently, Marc is focusing on his artistic vision of himself and coordinating various projects, honing his skills in the world of artistic direction.</div>
                  <a href="https://www.linkedin.com/in/marc-martin-riera/" rel="noopener noreferrer" target='_blank'><FaLinkedinIn className="linkedin-icon"/></a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="umbraImage">
                <img src={ImgUmbra03} alt="Berni Lopez" />
                <div id="overlay-03" className="overlay">
                  <div className='partner-title'>Berni López</div>
                  <div className='partner-subtitle'>Executive Producer</div>
                  <div className='partner-text'>Berni is a communication enthusiast who loves the media world. I studied my 'Journalism' degree in Barcelona while traveling around 36 different countries. I've been involved in social media environment for so long, creating communities for many international brands and TV shows. Interaction is my natural passion. My philosophy: a great effort springs naturally from a great attitude.</div>
                  <a href="https://www.linkedin.com/in/berni-lopez/" rel="noopener noreferrer" target='_blank'><FaLinkedinIn className="linkedin-icon"/></a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="umbraImage">
                <img src={ImgUmbra04} alt="Gaspar Segura" />
                <div id="overlay-04" className="overlay">
                  <div className='partner-title'>Gaspar Segura</div>
                  <div className='partner-subtitle'>Technical Supervisor</div>
                  <div className='partner-text'>Gaspar Segura is a valued partner at our company, bringing a wealth of experience and expertise to the team. Starting out as a CG Generalist, Gaspar has gradually transitioned into a more technical role, demonstrating a keen interest in developing new tools and optimizing processes. He is passionate about problem-solving and is dedicated to ensuring that our workflow runs smoothly. Gaspar's innovative mindset and commitment to excellence make him an invaluable asset to our organization.</div>
                  <a href="https://www.linkedin.com/in/gasparsegura/" rel="noopener noreferrer" target='_blank'><FaLinkedinIn className="linkedin-icon"/></a>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </Mobile>
    </>
  );
}