import React, { useEffect, useState, useRef } from 'react'
import { PiSpeakerSimpleHighBold, PiSpeakerSimpleSlashBold, PiPlayCircleBold, PiPauseCircleBold, PiArrowsOutBold, PiCaretDownBold, PiCaretUpBold } from "react-icons/pi";

import {FacebookIcon, LinkedinIcon, TelegramIcon, XIcon, WhatsappIcon } from "react-share";
import { FacebookShareButton, LinkedinShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import "./project.css";
import ImgLeftBlobs from "../../images/project/left.webp";
import ImgRightBlobs from "../../images/project/right.webp";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1025 })
  return isDesktop ? children : null
}

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 1024 })
  return isMobile ? children : null
}

export default function Project() {

  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);
  const { id } = useParams();
  const [project, setProject] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [creditsShow, setCreditsShow] = useState(false);

  const toggleIcon = () => {
    setCreditsShow(!creditsShow);
};

  const handleToggleMute = () => {
    videoRef.current.muted = !isMuted;
    setIsMuted(!isMuted);
  };

  const handleTogglePlay = () => {
    videoRef.current.play();
  };

  const handleTogglePause = () => {
    videoRef.current.pause();
  };

  const handleToggleScreen = () => {
    videoRef.current.requestFullscreen();
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true);
  };

  useEffect(() => {
    const fetchOurWorkData = async () => {
      try {
        const response = await fetch('/ourwork.json');
        const data = await response.json();
  
        const foundProject = data.find((work) => work.project === id);
  
        if (!foundProject) {
          console.log('No se encontró el proyecto');
          return;
        }
  
        setProject(foundProject);
        window.scrollTo(0, 0);
      } catch (error) {
        console.error('Error al cargar los datos:', error);
      }
    };
  
    fetchOurWorkData();
    
  }, [id]);

  const renderParagraphs = () => {
    if (project?.details) {
      const paragraphs = project?.details.split('\n');

      return paragraphs.map((paragraph, index) => (
        <div key={index} style={{ padding: '0px 0px 30px 0px' }}>{paragraph}</div>
      ));
    }
    return null;
  };

  const renderTitle = () => {
    if (project?.title_ourwork) {
      const paragraphs = project?.title_ourwork.split('\n');
      return paragraphs.map((paragraph, index) => (
        <div key={index}>{paragraph}</div>
      ));
    }
    return null;
  };

  const renderParagraphsEnd = () => {
    if (project?.comments) {
      const paragraphs = project?.comments.split('\n');

      return paragraphs.map((paragraph, index) => (
        <div key={index} style={{ padding: '0px 0px 30px 0px' }}>{paragraph}</div>
      ));
    }
    return null;
  };

  return (
    <>
      {isModalOpen && (
        <div className="modal" onClick={() => setIsModalOpen(false)}>
          <div className="modal-content">
            <img src={selectedImage} alt="selected-img" />
          </div>
        </div>
      )}
      <Desktop>
        <div className='projectContainer'>
          <img className='left-blobs' src={ImgLeftBlobs} alt="blob" data-aos="fade-right" data-aos-duration="1000" data-aos-once="true"/>
          <img className='right-blobs' src={ImgRightBlobs} alt="blob" data-aos="fade-left" data-aos-duration="1000" data-aos-once="true"/>
          <div className='titleProject'>{renderTitle()}</div>
          <div className='subTitleProject'>{project?.subtitle_ourwork}</div>
          <div className='yearProject'>{project?.year}</div>
          <div className='desc-project-container'>
            <div className='descriptionProject'>{project?.description}</div>
            <div className='share-container'>
              <div className='share-buttons'>
                <FacebookShareButton url={window.location.href} quote={project?.social}><FacebookIcon size={40} round={true}/></FacebookShareButton>
                <TwitterShareButton url={window.location.href} title={project?.social}><XIcon size={40} round={true}  /></TwitterShareButton>
                <LinkedinShareButton url={project?.share}><LinkedinIcon size={40} round={true}/></LinkedinShareButton>
                <WhatsappShareButton url={window.location.href} title={project?.social}><WhatsappIcon size={40} round={true}/></WhatsappShareButton>
                <TelegramShareButton url={window.location.href} title={project?.social}><TelegramIcon size={40} round={true}/></TelegramShareButton>
              </div>
            </div>
          </div>
          <div className='picContainer'>
            <div className='line-01-container'>
              {project && (
                <>
                  {project?.pic01.endsWith('.webm') ? (
                    <video className='sneakpic-movil' autoPlay playsInline muted loop controls controlsList="nodownload">
                      <source src={require(`../../images/project/${project?.pic01}`)} type='video/webm' />
                    </video>
                  ) : (
                    <img
                      className='sneakpic-movil'
                      src={require(`../../images/project/${project?.pic01}`)}
                      alt='sneakpic'
                      onClick={() => handleImageClick(require(`../../images/project/${project?.pic01}`))}
                    />
                  )}
                  {project?.pic02.endsWith('.webm') ? (
                    <video className='sneakpic-desktop' autoPlay playsInline muted loop controls controlsList="nodownload">
                      <source src={require(`../../images/project/${project?.pic02}`)} type='video/webm' />
                    </video>
                  ) : (
                    <img
                      className='sneakpic-desktop'
                      src={require(`../../images/project/${project?.pic02}`)}
                      alt='sneakpic'
                      onClick={() => handleImageClick(require(`../../images/project/${project?.pic02}`))}
                    />
                  )}
                </>
              )}
            </div>
            <div className='line-02-container'>
              {project && (
                  <>
                    {project?.pic03.endsWith('.webm') ? (
                      <video className='sneakpic-movil' autoPlay playsInline muted loop controls controlsList="nodownload">
                        <source src={require(`../../images/project/${project?.pic03}`)} type='video/webm' />
                      </video>
                    ) : (
                      <img
                        className='sneakpic-movil'
                        src={require(`../../images/project/${project?.pic03}`)}
                        alt='sneakpic-movil'
                        onClick={() => handleImageClick(require(`../../images/project/${project?.pic03}`))}
                      />
                    )}
                    {project?.pic04.endsWith('.webm') ? (
                      <video className='sneakpic-desktop' autoPlay playsInline muted loop controls controlsList="nodownload">
                        <source src={require(`../../images/project/${project?.pic04}`)} type='video/webm' />
                      </video>
                    ) : (
                      <img
                        className='sneakpic-desktop'
                        src={require(`../../images/project/${project?.pic04}`)}
                        alt='sneakpic'
                        onClick={() => handleImageClick(require(`../../images/project/${project?.pic04}`))}
                      />
                    )}
                  </>
                )}
            </div>
          </div>
          <div className='detailsProject'>{renderParagraphs()}</div>
          <div className='video-container'>
            {project && (
              <>
                <video ref={videoRef} autoPlay playsInline muted loop style={{ width: '100%', objectFit: 'cover' }} controlsList="nodownload">
                  <source src={require(`../../images/project/${project?.banner}`)} type="video/webm" />
                </video>
                <div className='projectPlayIcon' onClick={handleTogglePlay}><PiPlayCircleBold /></div>
                <div className='projectPauseIcon' onClick={handleTogglePause}><PiPauseCircleBold /></div>
                <div className='projectScreenIcon' onClick={handleToggleScreen}><PiArrowsOutBold /></div>
                <div className='projectSpeakerIcon' onClick={handleToggleMute}>{isMuted ? <PiSpeakerSimpleSlashBold /> : <PiSpeakerSimpleHighBold />}</div>
              </>
            )}
          </div>
          <div className='credits-container'onClick={toggleIcon}>
            <div className='creditsTitle'>
              <div className='credits'>Credits</div>
              <div className='creditsIcon'>
                {creditsShow ? <PiCaretUpBold /> : <PiCaretDownBold />}
              </div>
            </div>
            <div className={`creditsDetails ${creditsShow ? 'active' : ''}`}>
              {Object.entries(project?.credits || {}).map(([key, value]) => (
                value && <div key={key} className='creditsText'>{key.charAt(0).toUpperCase() + key.slice(1)}: {value}</div>
              ))}
            </div>
          </div>
        </div>
      </Desktop>
      <Mobile>
        <div className='projectContainer'>
          <img className='left-blobs' src={ImgLeftBlobs} alt="blob" data-aos="fade-right" data-aos-duration="1000" data-aos-once="true"/>
          <img className='right-blobs' src={ImgRightBlobs} alt="blob" data-aos="fade-left" data-aos-duration="1000" data-aos-once="true"/>
          <div className='titleProject'>{renderTitle()}</div>
          <div className='subTitleProject'>{project?.subtitle_ourwork}</div>
          <div className='yearProject'>{project?.year}</div>
          <div className='desc-project-container'>
            <div className='descriptionProject'>{project?.description}</div>
              <div className='share-container'>
                <div className='share-buttons'>
                  <FacebookShareButton url={window.location.href} quote={project?.social}><FacebookIcon size={35} round={true} /></FacebookShareButton>
                  <TwitterShareButton url={window.location.href} title={project?.social}><XIcon network='x' size={35} round={true} /></TwitterShareButton>
                  <LinkedinShareButton url={project?.share}><LinkedinIcon size={35} round={true} /></LinkedinShareButton>
                  <WhatsappShareButton url={window.location.href} title={project?.social}><WhatsappIcon size={35} round={true} /></WhatsappShareButton>
                  <TelegramShareButton url={window.location.href} title={project?.social}><TelegramIcon size={35} round={true} /></TelegramShareButton>
                </div>
              </div>
          </div>
          <div className='picContainer'>
          <div className='line-01-container'>
              {project && (
                <>
                  {project?.pic01.endsWith('.webm') ? (
                    <video className='sneakpic-movil' autoPlay playsInline muted loop controls controlsList="nodownload">
                      <source src={require(`../../images/project/${project?.pic01}`)} type='video/webm' />
                    </video>
                  ) : (
                    <img
                      className='sneakpic-movil'
                      src={require(`../../images/project/${project?.pic01}`)}
                      alt='sneakpic'
                    />
                  )}
                  {project?.pic02.endsWith('.webm') ? (
                    <video className='sneakpic-desktop' autoPlay playsInline muted loop controls controlsList="nodownload">
                      <source src={require(`../../images/project/${project?.pic02}`)} type='video/webm' />
                    </video>
                  ) : (
                    <img
                      className='sneakpic-desktop'
                      src={require(`../../images/project/${project?.pic02}`)}
                      alt='sneakpic'
                    />
                  )}
                </>
              )}
            </div>
            <div className='line-02-container'>
              {project && (
                  <>
                    {project?.pic03.endsWith('.webm') ? (
                      <video className='sneakpic-movil' autoPlay playsInline muted loop controls controlsList="nodownload">
                        <source src={require(`../../images/project/${project?.pic03}`)} type='video/webm' />
                      </video>
                    ) : (
                      <img
                        className='sneakpic-movil'
                        src={require(`../../images/project/${project?.pic03}`)}
                        alt='sneakpic'
                      />
                    )}
                    {project?.pic04.endsWith('.webm') ? (
                      <video className='sneakpic-desktop' autoPlay playsInline muted loop controls controlsList="nodownload">
                        <source src={require(`../../images/project/${project?.pic04}`)} type='video/webm' />
                      </video>
                    ) : (
                      <img
                        className='sneakpic-desktop'
                        src={require(`../../images/project/${project?.pic04}`)}
                        alt='sneakpic'
                      />
                    )}
                  </>
                )}
            </div>
          </div>
          <div className='detailsProject'>{renderParagraphs()}</div>
          <div className='video-container'>
            {project && (
              <>
                <video ref={videoRef} autoPlay playsInline muted loop style={{ width: '100%', objectFit: 'contain' }} controlsList="nodownload">
                  <source src={require(`../../images/project/${project?.banner}`)} type="video/webm" />
                </video>
                <div className='projectPlayIcon' onClick={handleTogglePlay}><PiPlayCircleBold /></div>
                <div className='projectPauseIcon' onClick={handleTogglePause}><PiPauseCircleBold /></div>
                <div className='projectSpeakerIcon' onClick={handleToggleMute}>{isMuted ? <PiSpeakerSimpleSlashBold /> : <PiSpeakerSimpleHighBold />}</div>
              </>
            )}
          </div>
          <div className='comments-project'>{renderParagraphsEnd()}</div>
          <div className='credits-container' onClick={toggleIcon}>
            <div className='creditsTitle'>
              <div className='credits'>Credits</div>
              <div className='creditsIcon'>
                {creditsShow ? <PiCaretUpBold /> : <PiCaretDownBold />}
              </div>
            </div>
            <div className={`creditsDetails ${creditsShow ? 'active' : ''}`}>
              {Object.entries(project?.credits || {}).map(([key, value]) => (
                value && <div key={key} className='creditsText'>{key.charAt(0).toUpperCase() + key.slice(1)}: {value}</div>
              ))}
            </div>
          </div>
        </div>
      </Mobile>
    </>
  )
}