import React from 'react'
import "./error.css";

export default function Error() {

  return (
      <div className='errorContainer'>
        <div className='alertContainer'></div>
        <div className='errorBlobsContainer'>
          <div className='errorTitle'>OOPS!</div>
          <div className='errorText-01'>The page you've requested may not be able or can be under construction.</div>
          <div className='errorText-02'>sorry for the inconvenience</div>
        </div>
      </div>
  );
}