import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'

export const Nav = styled.nav`
  display:flex;
  position: sticky;
  justify-content: center;
  align-items: center;
  top: 0px;
  z-index: 9999;

  @media (max-width: 1024px) {
    height: 80px;
  }
`

export const NavbarContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: #1D1D1F;
  align-items: center;
  z-index: 9999;

  @media (max-width: 1024px) {
    height: 80px;
  }
`

export const LogoContainer = styled.div`
  display: flex;
  width: 50%;
`

export const IconContainer = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-end;
`

export const NavLogo = styled(LinkR) `
  display: flex;
  justify-self: left;
  align-item: center;
  font-family: "Schear-Grotesk-Bold";
  font-size: 3.2vw;
  color: #fff;
  text-decoration:none;
  letter-spacing: 0.1vw;
  padding: 0.5vw 0vw 0.5vw 1.5vw;

  @media (max-width: 1024px) {
    font-size: 40px;
    padding-left: 20px;
  }
`
export const MobileIcon = styled.div`
  display: flex;
  font-size: 2.1vw;
  cursor: pointer;
  color: #fff;
  padding: 0.5vw 1.5vw 0.5vw 0vw;

  @media screen and (max-width: 1024px) {
    font-size: 30px;
    padding-right: 20px;
  }
`
export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 6.2vw;

  @media (max-width: 1024px) {
    margin-top: 0px;
  }

`

export const MenuOptions = styled.a`
  display: inline;
  font-family: "Schear-Grotesk";
  font-size: 3vw;
  color: #F5F5F7;
  text-decoration:none;
  margin: 0vw 17.7vw 0.8vw 0vw;
  letter-spacing: 1.5vw;
  text-align: right;
  user-select: none;
  transition: 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    font-size: 4vw;
    border-right: 1px solid;
    transition: 0.2s ease-in-out;
  }

  @media (max-width: 1024px) {
    font-size: 20px;
    margin: 0px 50px 10px 0px;
    letter-spacing: 10px;

    &:hover {
      cursor: pointer;
      font-size: 30px;
      border-right: 1px solid;
      transition: 0.2s ease-in-out;
    }
  }

`