import React from 'react'

import Navbar from '../components/Navbar'
import PageNotFound from '../components/Error'
import Footer from '../components/Footer'

import '../App.css'

const Error = () => {

  return (
    <>
      <Navbar />
      <PageNotFound />
      <Footer />
    </>
  )
}

export default Error
