import React, { useState } from 'react'
import { HiBars2, HiOutlineXMark } from 'react-icons/hi2'
import { CSSTransition } from 'react-transition-group';

import { Nav, NavbarContainer, NavLogo, MobileIcon, LogoContainer, IconContainer, MenuOptions, MenuContainer} from './NavbarElements'

const Navbar = ({ toggle }) => {

  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <Nav>
        <NavbarContainer>
          <LogoContainer>
            <NavLogo to="/">FIRELIGHT®</NavLogo>
          </LogoContainer>
          <IconContainer>
            {menuOpen ? (
                  <MobileIcon onClick={handleMenu}><HiOutlineXMark /></MobileIcon>
                ) : (
                  <MobileIcon onClick={handleMenu}><HiBars2 /></MobileIcon>
                )}
          </IconContainer>
        </NavbarContainer>
        <CSSTransition in={menuOpen} timeout={1000} classNames="menu-transition" unmountOnExit>
          <div className="menu-floating">
            <MenuContainer>
              <MenuOptions href="/about-us">ABOUT US</MenuOptions>
              <MenuOptions href="/our-work">OUR WORK</MenuOptions>
              <MenuOptions href="/contact-us">CONTACT</MenuOptions>
              <MenuOptions href="/careers">CAREERS</MenuOptions>
            </MenuContainer>
          </div>
        </CSSTransition>
      </Nav>
    </>
  )
}

export default Navbar
