import React from 'react'

import Navbar from '../components/Navbar'
import AboutUs from '../components/AboutUs'
import Footer from '../components/Footer'

import '../App.css'

const Home = () => {

  return (
    <>
      <Navbar />
      <AboutUs />
      <Footer />
    </>
  )
}

export default Home
