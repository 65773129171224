import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import "./ourwork.css";
import { useMediaQuery } from 'react-responsive';

import ImgBlobs01 from "../../images/ourwork/1.webp";
import ImgBlobs02 from "../../images/ourwork/2.webp";
import ImgBlobs03 from "../../images/ourwork/3.webp";
import ImgBlobs04 from "../../images/ourwork/ourWork.webp";


const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1025 })
  return isDesktop ? children : null
}

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 1024 })
  return isMobile ? children : null
}

export default function OurWork() {

  const [works, setWorks] = useState([]);

  useEffect(() => {
    fetch('/ourwork.json')
      .then(response => response.json())
      .then(data => setWorks(data))
      .catch(error => console.error('Error fetching vacancies:', error));
  }, []);

  return (
    <>
      <Desktop>
        <div className='ourWorkContainer'>
          <img className='blobs_01' src={ImgBlobs01} alt="blob" />
          <img className='blobs_02' src={ImgBlobs02} alt="blob" />
          <img className='blobs_03' src={ImgBlobs03} alt="blob" />
          <img className='blobs_04' src={ImgBlobs04} alt="blob" />
          <div className='titleOurWork'>OUR WORK</div>
          <div className='cardContainer'>
            {works.map((work, index) => (
              <div className="card" key={index} data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
                <Link to={`${work.url}`}>
                  <img className="cardImage" src={require(`../../images/ourwork/${work.image}`)} alt="work"/>
                </Link>
                <div className="cardTitle">{work.title}</div>
                <div className="cardSubTitle">{work.subtitle}</div>
              </div>
            ))}
          </div>
        </div>
      </Desktop>
      <Mobile>
        <div className='ourWorkContainer'>
          <img className='blobs_01' src={ImgBlobs01} alt="blob" />
          <img className='blobs_02' src={ImgBlobs02} alt="blob" />
          <img className='blobs_03' src={ImgBlobs03} alt="blob" />
          <div className='titleOurWork'>OUR WORK</div>
          <div className='cardContainer'>
            {works.map((work, index) => (
              <div className="card" key={index} data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
                <Link to={`${work.url}`}>
                  <img className="cardImage" src={require(`../../images/ourwork/${work.image}`)} alt="work"/>
                </Link>
                <div className="cardTitle">{work.title}</div>
                <div className="cardSubTitle">{work.subtitle}</div>
                </div>
            ))}
          </div>
        </div>
      </Mobile>
    </>
  );
}